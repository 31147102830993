import { useDispatch, useSelector } from 'react-redux';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { renderToString } from 'react-dom/server';
import { resetConfig, selectGrade } from 'store/entities/config';
import { resetBuilderData, setBodyData } from 'store/entities/data';
import { resetAppState } from 'store/entities/app';
import LocalizedLink from 'components/LocalizedLink';
import Price from 'components/Price';
import { ReactComponent as Info } from 'assets/images/icons/Info.svg';

export const GradeCard = ({ gradeData }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { specificationsData } = useSelector(state => state.data);
  const { grade, eic, model, body, engine, price } = gradeData;

  const handleGradeSelect = () => {
    dispatch(resetConfig());
    dispatch(resetBuilderData());
    dispatch(resetAppState());
    dispatch(selectGrade({ modelId: model?.id, specEic: eic, gradeId: grade.id, bodyId: body?.id, engineId: engine?.id }));
    dispatch(setBodyData(specificationsData[grade.id]));
  };

  const badge = ['hybrid', 'electric'].filter(item => engine?.type.toLowerCase().includes(item))[0];

  return (
    <div className="c-grade">
      <figure className="c-grade__img">
        <img src={model?.pictures?.main} alt={grade?.name} />
      </figure>
      <div className="c-grade__body">
        <h3 className="c-grade__title">{grade?.name}</h3>
        <span className="c-badge-list">
          <span className={`c-badge c-badge--${badge || 'default'}`}>{engine?.type}</span>
        </span>
        <p className="c-grade__price">
          <strong className="c-price c-price--new">
            <span className="c-price__title">{t('starting_price', { price: renderToString(<Price price={price} />) })}</span>
            {/*<small className="c-price__hint">
            <OverlayTrigger
              overlay={
                <Tooltip id={`tooltip-${grade.id}`}>
                  Some tooltip text
                </Tooltip>
              }
            >
              <Info className="c-price__hint-ico" />
            </OverlayTrigger>
            </small>*/}
          </strong>
        </p>
        {/*<p className="c-grade__descr">{gradeData?.name}</p>*/}
      </div>
      <div className="c-grade__footer">
        <p className="c-grade__tech">{body?.name}</p>
        <p className="c-grade__btnline">
          <LocalizedLink to="/select">
            <Button variant="primary" onClick={handleGradeSelect}>
              {t('grade_select_button')}
            </Button>
          </LocalizedLink>
        </p>
      </div>
    </div>
  );
};
